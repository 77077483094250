/**
 * @name: 通用方法
 * @author: itmobai
 * @date: 2023-03-14 16:55
 * @description：通用方法
 * @update: 2023-03-14 16:55
 */
/**
 * 根据列表值获取名字
 * @param list
 * @param value
 */
export const getLabelByValue = (list: {label: string, value: string | number}[], value: string | number) => {
  if(!list || list.length == 0 || value === null || value === undefined || value === "") {
    return ""
  }
  return list.find(item => item.value === value)!["label"]
}

/**
 * 将后端返回的带单转换成可用的路由
 * @param routeList 后端路由数据
 * @returns
 */
export const generateRoutes = (routeList: any) => {
  const routes: any = [];
  routeList.forEach((item: any) => {
    const route: any = {};
    route.path = item.path;
    route.name = item.name;
    if (item.pid == 0) {
      route.component =() => import('@/layout/index.vue');
    } else {
      route.component =() => import(`@/views${item.component}.vue`);
    }
    route.meta = {
      title: item.meta.title,
      icon: item.meta.icon,
      isKeepAlive: item.meta.isKeepAlive
    };
    if (item.children) {
      route.children = generateRoutes(item.children);
    }
    routes.push(route);
  });
  return routes;
}

/**
 * 深拷贝
 * @param obj
 */
export function deepCopy (obj: any): any {
  let result;
  if (Array.isArray(obj)) {
    result = [];
    for (let i = 0; i < obj.length; i++) {
      result[i] = deepCopy(obj[i]);
    }
  } else if (typeof obj === 'object' && obj !== null) {
    result = {};
    for (let key in obj) {
      // @ts-ignore
      result[key] = deepCopy(obj[key]);
    }
  } else {
    result = obj;
  }
  return result;
}

/**
 * 处理对象空参数
 * @param obj 对象
 */
export function removeEmpty(obj: any) {
  if (!obj) return;
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
    else if (obj[key] === null || obj[key] === undefined || obj[key] === "") delete obj[key];
  });
  return obj;
}

/**
 * 导出文件
 * @param res blob数据
 * @param fileName 文件名
 */
export function exportFile(res: Blob, fileName: string) {
  const link = document.createElement('a')  //创建a标签
  const blob = new Blob([res]) //设置文件流
  link.style.display = 'none'
  // 设置连接
  link.href = URL.createObjectURL(blob)  //将文件流转化为blob地址
  link.download = fileName;
  document.body.appendChild(link)
  // 模拟点击事件
  link.click()  //设置点击事件
  document.body.removeChild(link)
}

/**
 * 根据物流code获取状态
 * @param statusCode
 */
export function getlogisticStatus(statusCode: number) {
  if (statusCode === 1 || statusCode === 101 || statusCode === 102 || statusCode === 103) {
    return "揽收"
  }
  if (statusCode === 0 || statusCode === 1001 || statusCode === 1002 || statusCode === 1003) {
    return "在途"
  }
  if (statusCode === 5 || statusCode === 501) {
    return "派件"
  }
  if (statusCode === 3 || statusCode === 301 || statusCode === 302 || statusCode === 303 || statusCode === 304) {
    return "签收"
  }
  if (statusCode === 6) {
    return "退回"
  }
  if (statusCode === 4 || statusCode === 401 || statusCode === 14) {
    return "退签"
  }
  if (statusCode === 7) {
    return "转投"
  }
  if (statusCode === 2 || (statusCode >= 201 || statusCode <= 210)) {
    return "疑难"
  }
  if (statusCode === 8 || (statusCode >= 10 || statusCode <= 13)) {
    return "清关"
  }
}

/**
 * 获取上一天和今天的某个时间
 * @param startTime 开始时间
 * @param endTime 结束时间
 */
export function getPreAndNextTime (startTime: string, endTime: string): string[] {
  const now = new Date()
  const pre = new Date(now.getTime() - (24 * 60 * 60 * 1000))
  return [
    `${pre.getFullYear()}-${(pre.getMonth() + 1).toString().padStart(2, '0')}-${pre.getDate().toString().padStart(2, '0')} ${startTime}`,
    `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${endTime}`,
  ]
}
