/**
 * @name: http请求封装
 * @author: itmobai
 * @date: 2024-07-17 17:25
 * @description：http请求封装
 * @update: 2024-07-17 17:25
 */
import axios from 'axios'
import store from '@/store'

import { Message, MessageBox } from 'element-ui'

import config from '@/config';

import { deepCopy, removeEmpty } from '@/utils/common'

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios'
import type { IHttpRequestOptions, IHttpResponse, IServerResponse } from "./types";

export default class Http {

  // 请求配置
  private requestOptions!: IHttpRequestOptions;

  // axios实例
  private axiosInstance!: AxiosInstance;

  /**
   * 初始化http
   * @param options http配置
   */
  public constructor(options: IHttpRequestOptions) {
    this.requestOptions = { ...options }

    if (!options.timeOut) {
      // 超时时间默认1分钟
      this.requestOptions.timeOut = config.timeOut
    }
    // 创建实例
    this.axiosInstance = axios.create({
      baseURL: this.requestOptions.base,
      timeout: this.requestOptions.timeOut,
    })

    /**
     * 请求拦截
     */
    this.axiosInstance.interceptors.request.use(
      config => {
        const headers: any = {}
        if (store.getters.token) {
          config.headers = Object.assign(headers, this.requestOptions.headers, { 'Authorization': `Bearer ${store.getters.token}` })
        } else {
          config.headers = Object.assign(headers, this.requestOptions.headers)
        }
        return config
      },
      error => {
        return Promise.reject(error);
      }
    )

    /**
     * 响应拦截
     */
    this.axiosInstance.interceptors.response.use(
      // @ts-ignore
      async (response: AxiosResponse<any>): Promise<IHttpResponse<any>> => {
        if (response.status === 200 || response.status === 201) {
          // 响应头
          const headers = response.headers;
          // 判断是不是非文件流
          console.log(headers['content-type'])
          if (headers['content-type'] === 'application/json') {
            // 普通数据
            if (response.data && response.data.code === 0) {
              // 数据正常
              return {
                data: response.data.data,
                headers
              }
            }
            if (response.data && (response.data.code == 401 || response.data.code == 403)) {
              // 鉴权错误
              await this.reLogin();
              return Promise.reject(response.data)
            }
            Message({
              message: response.data.message || "网络请求失败",
              type: 'error',
              duration: 5 * 1000,
            })
            return Promise.reject(response.data)
          } else {
            console.log(response.data)
            // 文件流
            return {
              data: response.data,
              headers
            }
          }
        } else {
          if (response.status === 401) {
            await this.reLogin();
          }
          return Promise.reject(response)
        }
      },
      error => {
        let {message} = error;
        if (message == "Network Error") {
          message = "网络连接异常";
        } else if (message.includes("timeout")) {
          message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
          message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Message({
          message: message || "网络请求失败",
          type: 'error',
          duration: 5 * 1000,
        });
        return Promise.reject(error);
      }
    )
  }

  /**
   * 登录过期，重新登录
   * @returns
   */
  private reLogin() {
    return new Promise((resolve) => {
      MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        store.dispatch("useUserStore/logOut").then(() => {
          resolve(null)
          location.href = process.env.BASE_URL + 'login';
        })
      }).catch(() => {
        resolve(null)
      });
    })
  }

  /**
   * 主要请求
   * @param config 请求配置
   */
  private request<T = any>(config: AxiosRequestConfig): Promise<IHttpResponse<T>> {
    return new Promise(async (resolve, reject) => {
      this.axiosInstance.request(config).then((res: AxiosResponse<IServerResponse | Blob>) => {
        if (res) {
          resolve(res as any)
        } else {
          reject(res)
        }
      }).catch(err => {
        reject(err)
      })
    })
  }

  /**
   * http get请求
   * @param url 请求地址
   * @param params 请求参数
   */
  public get<T = any>(url: string, params?: any, resType?: ResponseType): Promise<IHttpResponse<T>> {
    const query: any = deepCopy(params || {})
    // 处理空数据参数
    removeEmpty(query)
    return this.request({
      url,
      method: 'GET',
      params: query,
      responseType: resType || 'json'
    })
  }

  /**
   * http post json数据
   * @param url 请求地址
   * @param data 请求体
   * @returns
   */
  public postJ<T = any>(url: string, data?: any, resType?: ResponseType): Promise<IHttpResponse<T>> {
    return this.request({
      url,
      method: 'POST',
      data,
      headers: {'Content-Type': 'application/json'},
      responseType: resType || 'json'
    })
  }

  /**
   * http post x-www-form-urlencoded数据
   * @param url 请求地址
   * @param data 请求体
   * @returns
   */
  public postW<T = any>(url: string, data?: any, resType?: ResponseType): Promise<IHttpResponse<T>> {
    return this.request({
      url,
      method: 'POST',
      data,
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      responseType: resType || 'json'
    })
  }

  /**
   * http post multipart/form-data数据
   * @param url 请求地址
   * @param data 请求体
   * @returns
   */
  public postF<T = any>(url: string, data?: FormData, resType?: ResponseType): Promise<IHttpResponse<T>> {
    return this.request({
      url,
      method: 'POST',
      data,
      headers: {'Content-Type': 'multipart/form-data'},
      responseType: resType || 'json'
    })
  }

  /**
   * http put
   * @param url 请求地址
   * @param data 请求体
   * @returns
   */
  public put<T = any>(url: string, data?: FormData, resType?: ResponseType): Promise<IHttpResponse<T>> {
    return this.request({
      url,
      method: 'PUT',
      data,
      responseType: resType || 'json'
    })
  }

  /**
   * http delete
   * @param url 请求地址
   * @param data 请求体
   * @returns
   */
  public del<T = any>(url: string, data?: FormData, resType?: ResponseType): Promise<IHttpResponse<T>> {
    return this.request({
      url,
      method: 'DELETE',
      data,
      responseType: resType || 'json'
    })
  }
}
