// import {adminGetAccountInfoApi, adminLoginApi} from "@/apis/core";
import { setToken } from "@/utils/token";
import {getAccountInfoApi, userLoginApi} from "@/apis/core";

const useUserStore = {
  namespaced: true,
  state: {
    userInfo: {},
    token: ''
  },

  mutations: {
    setUserInfo(state: any, userInfo: any) {
      state.userInfo = userInfo;
    },
    // 设置token
    SET_TOKEN(state: any, token: string) {
      state.token = token
    }
  },
  actions: {
    async logOut(ctx: any, data: any){
      return new Promise(resolve => {
        ctx.commit('SET_TOKEN','')
        ctx.rootState.routerFlag = false
        setToken('')
        resolve('')
      })
    },
    async login({commit}: any, data: any) {
      return new Promise(resolve => {
        userLoginApi(data).then(e => {
          commit('SET_TOKEN', e.data)
          setToken(e.data)
          resolve(e.data)
        })
      })
    },
    getCode() {
    },
    /**
     * 获取用户信息
     * @param ctx
     */
    getUserInfos({commit}: any) {
      getAccountInfoApi().then(e => {
        commit("setUserInfo", e.data)
      })
    }
  },
}

export default useUserStore

