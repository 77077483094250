import api from '@/request/api'
import type { ILoginParam, IMenu } from "@/apis/core/types";

/**
 * 获取后端菜单
 */
export const getMenuApi = () => api.get<IMenu[]>("/admin/admin/getMenuTree")
/**
 * 获取按钮权限
 * @returns
 */
export const getPermission = () => api.get<string[]>("/admin/admin/gePermissions")
/**
 * 用户登录
 * @param id 用户data
 */
export const userLoginApi = (data: ILoginParam) => api.get("/admin/admin/login", data)
/**
 * 获取用户信息
 * @param id
 */
export const getAccountInfoApi = () => api.get("/admin/admin/getAccountInfo")
/**
 * 修改密码
 * @param pwd
 * @param newPwd
 * @returns
 */
export const changePassword = (pwd: string, newPwd: string) => api.postJ("/admin/admin/changePassword", { pwd, newPwd })
