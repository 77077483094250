/**
 * @name: api前缀请求入口
 * @author: itmobai
 * @date: 2024-07-17 17:25
 * @description：api前缀请求入口
 * @update: 2024-07-17 17:25
 */
import Http from './http'
import config from '@/config'

const apiHttp = new Http({
  base: '/api',
  timeOut: config.timeOut
})

export default apiHttp
